<template>
    <div class="row justify-content-center">
        <b-modal
                id="friend-help"
                ok-title="確認"
                @ok="authorFriend"
                title="請朋友幫忙領"
                cancel-title="取消"
                @hidden="resetModal"
        >
            <b-form-group
                    label-cols-sm="3"
                    label="輸入學號："
                    label-align-sm="right"
                    label-for="store"
                    description="請注意，輸入的學號必須要是住宿生，不然他也進不去包裹室幫你領包裹；想清除請留空。"
            >
                <b-form-input v-model="packages.modal.stuId" id="place-m"/>
            </b-form-group>
        </b-modal>
        <div class="col-lg-4">
            <b-form v-if="false">
                <b-form-group
                        id="fieldset-1"
                        description="如果你預先登記了這項資訊，你就可以在我們登入包裹的瞬間收到訊息。"
                        label="輸入包裹追蹤號碼"
                        label-for="input-1"
                >
                    <b-input-group class="mt-3">
                        <template v-slot:append>
                            <b-button>送出</b-button>
                        </template>
                        <b-form-input trim/>
                    </b-input-group>


                </b-form-group>
            </b-form>
            <b-card title="郵件須知" sub-title="把包過寄來宿舍，意味著你同意學校規定與下列事項">
                <b-card-text>
                    <b-alert :show="true" variant="info"><a href="https://dga.nttu.edu.tw/p/412-1008-9327.php?Lang=zh-tw">本文為簡略版，若有任何與正式規定衝突之處，以學校規定為準。</a></b-alert>
                    <ul>
                        <li>包裹若屬貴重或有其他疑慮之物品，請自行與物流貨運公司聯繫親領。</li>
                        <li>生鮮、水果、食品、冷藏冷凍、急件、活體、代收貨款、具時效性文件(限時或其無法於開放時間領取的物品)或現金袋，請與物流業者自行聯絡收件，凡前述物品，宿舍將逕行拒收或退件。具時效性文件宿舍不負責通知或提前簽領。</li>
                        <li>請收件人攜帶身分證件以供領取，無法親自領取可委託親友持收件人身分證件並檢附收件人身分證明文件，則可代領。已收之包裹信件若超過一個月無人領件，將公告後2周內視同廢棄物處理。</li>
                        <li>宿舍僅收住宿生包裹，務必標明學號、房號、宿舍別、姓名及電話；若當下無法確定包裹屬於住宿生，則將拒收。</li>
                        <li>以上查詢以本系統為準，改名、換電話等個資變更個人資料請使用本系統或洽行政辦公室處理</li>
                    </ul>
                </b-card-text>

                <b-card-text>
                    免責聲明：包裹之保管、通知非宿舍義務，本項服務僅為義務性質，若有任何疑慮，請聯絡物流業者親自領取。
                </b-card-text>
                <b-card-footer>
                    <b-row>
                        <b-col><b-button pill variant="success" block :to="{name: 'student_area_home'}">設定Line通知</b-button></b-col>
                        <b-col><b-button pill variant="success" block :to="{name: 'student_area_email'}">更改電子郵件</b-button></b-col>
                        <b-col><b-button pill variant="primary" block @click="syncList">更新列表</b-button></b-col>
                    </b-row>
                </b-card-footer>

            </b-card>
        </div>
        <div class="col">
            <b-card>
                <b-table id="my-package-table"
                         striped
                         hover
                         :items="packages.items"
                         :fields="packages.fields"
                         :current-page="packages.currentPage"
                         :per-page="packages.perPage"
                >
                    <template v-slot:row-details="row">
                        <ul>
                            <li>物流編號：{{row.item.more.serial}}</li>
                            <li>簽收時間：{{row.item.more.receive_time}}</li>
                            <li>物流業者：{{row.item.more.logistics}}</li>
                            <li>代領：{{row.item.more.friend_name}}</li>
                        </ul>
                        <p>備註：<br>{{row.item.more.remark}}</p>
                        <b-button variant="primary" v-if="row.item.packageStatus === '待領'" @click="showModal(row.item['uuid'])">請人代領</b-button>
                    </template>
                    <template v-slot:cell(packageActions)="row">
                        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                            {{ row.detailsShowing ? '更少' : '更多'}} 操作
                        </b-button>
                    </template>
                </b-table>
                <b-pagination
                        v-model="packages.currentPage"
                        :total-rows="packages.items.length"
                        :per-page="packages.perPage"
                        aria-controls="my-package-table"
                />
            </b-card>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "StudentPackage",
        data: function () {
            return {
                packages: {
                    fields: [
                        {
                            key: 'packageId',
                            label: '包裹編號',
                            sortable: true
                        },
                        {
                            key: 'packagePlace',
                            label: '放置地點'
                        },
                        {
                            key: 'package_rTime',
                            label: '建單時間'
                        },
                        {
                            key: 'packageStatus',
                            label: '目前狀態'
                        },
                        {
                            key: 'packageActions',
                            label: '操作'
                        }
                    ],
                    items: [],
                    currentPage: 1,
                    perPage: 10,
                    modal: {
                        stuId: '',
                        pid: ''
                    }
                }
            }
        },
        methods:{
            syncList(){
                axios.get('/api/package/myPackages')
                    .then((resp)=>{
                        this.packages.items = resp.data;
                    })
            },
            authorFriend(){
                const formData = new FormData();
                let config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                formData.append('fid', this.packages.modal.stuId);
                formData.append('p_uuid', this.packages.modal.pid);
                axios.post('/api/package/myPackages', formData, config).then((resp)=>{
                    window.alert(resp.data.msg);
                    this.syncList();
                }).catch((error)=>{
                    if(error.response){
                        window.alert(error.response.data.msg);
                    }else {
                        window.alert('出錯了QQ');
                        window.console.log(error);
                    }
                    this.syncList();
                })
            },
            showModal(pid){
                this.packages.modal.pid = pid;
                this.$bvModal.show('friend-help')
            },
            resetModal(){
                this.packages.modal = {
                    stuId: '',
                    pid: ''
                };
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                this.syncList();
            })
        }
    }
</script>

<style scoped>

</style>